import logo from './valence-logo.png'; // Ensure the path is correct based on your project structure
import React, { useState } from 'react';
import './App.css'; // Import your CSS file

function App() {
  // State hooks
  const [searchCode, setSearchCode] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [error, setError] = useState(null);
  const [deductionAmount, setDeductionAmount] = useState('');
  const [restaurant, setRestaurant] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [receiptNumber, setReceiptNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  // Function to handle the search
  const handleSearch = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true); // Set loading to true when the search starts
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/giftcards/${searchCode.trim().toUpperCase()}`);
      if (!response.ok) {
        throw new Error('Server returned an error response');
      }
  
      const contentType = response.headers.get('content-type');
  
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Unexpected response format');
      }
  
      const data = await response.json();
      setSearchResult(data);
    } catch (error) {
      console.error('Error fetching gift card:', error);
      setSearchResult(null);
      setError('The server returned an unexpected response.');
    } finally {
      setLoading(false); // Set loading to false after the search completes
    }
  };
  
  const handleDeduction = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true); // Set loading to true when deduction starts
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/giftcards/deduct`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: searchResult?.code,
          amount: deductionAmount,
          name: employeeName,
          restaurant: restaurant,
          receiptNumber: receiptNumber,
        }),
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
  
      setSuccessMessage(
        `You have successfully redeemed ${deductionAmount-data.cashToCollect} QAR. Collect ${data.cashToCollect} QAR from the customer.`

      );
    } catch (error) {
      console.error('Error deducting amount:', error);
      setError('Failed to deduct the amount. Please try again.');
    } finally {
      setLoading(false); // Set loading to false after deduction completes
    }
  };
  

  // Function to reset the form for another redemption
  const handleRedeemAnother = () => {
    setSearchCode('');
    setSearchResult(null);
    setDeductionAmount('');
    setRestaurant('');
    setEmployeeName('');
    setReceiptNumber('');
    setSuccessMessage(null);
  };

  return (
    <div className="container">
      <img src={logo} alt="Valence Group Logo" className="logo" /> {/* Add the logo here */}
      <h1>Gift Card Management</h1>
      {successMessage ? (
        <div className="success-message">
          <div className="checkmark">✔</div>
          <p>{successMessage}</p>
          <button onClick={handleRedeemAnother}>Redeem Another Card</button>
        </div>
      ) : (
        <>
          <form onSubmit={handleSearch}>
            <label htmlFor="giftCode">Gift Card Code:</label>
            <input
              type="text"
              id="giftCode"
              value={searchCode}
              onChange={(e) => setSearchCode(e.target.value)}
              placeholder="Enter Gift Card Code"
              required
            />
            <button type="submit" disabled={loading}>
              {loading ? 'Searching...' : 'Search'}
            </button>
          </form>

          {error && <p className="error">{error}</p>}

          {searchResult && (
            <div className="result">
              <h2>Gift Card Found:</h2>
              <form onSubmit={handleDeduction}>
                <label htmlFor="deductAmount">Amount to be deducted:</label>
                <input
                  type="number"
                  id="deductAmount"
                  value={deductionAmount}
                  onChange={(e) => setDeductionAmount(e.target.value)}
                  placeholder="Enter amount"
                  min="1"
                  required
                />
                <label htmlFor="restaurant">Select Your Restaurant:</label>
                <select
                  id="restaurant"
                  value={restaurant}
                  onChange={(e) => setRestaurant(e.target.value)}
                  required
                >
                  <option value="">Select a restaurant</option>
                  <option value="TAWAR- REEM AL BAWADI">TAWAR- REEM AL BAWADI</option>
                  <option value="HILTON- REEM AL BAWADI">HILTON- REEM AL BAWADI</option>
                  <option value="DUSITD2- LEMON&AZKA">DUSITD2- LEMON&AZKA</option>
                  <option value="WYNDHAM- LEMON">WYNDHAM- LEMON</option>
                  <option value="QATAR MALL- LEMON">QATAR MALL- LEMON</option>
                  <option value="TAWAR- LEMON">TAWAR- LEMON</option>
                  <option value="WYNDHAM- TCHE TCHE">WYNDHAM- TCHE TCHE</option>
                  <option value="974- TCHE TCHE">974- TCHE TCHE</option>
                  <option value="MOVENPICK- TCHE TCHE">MOVENPICK- TCHE TCHE</option>
                  <option value="THE PEARL- TCHE TCHE">THE PEARL- TCHE TCHE</option>
                  <option value="MILLENIUM- TCHE TCHE">MILLENIUM- TCHE TCHE</option>
                </select>
                <label htmlFor="employeeName">Please Enter your Name:</label>
                <input
                  type="text"
                  id="employeeName"
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  placeholder="Enter your name"
                  required
                />
                <label htmlFor="receiptNumber">Enter Receipt Number:</label>
                <input
                  type="text"
                  id="receiptNumber"
                  value={receiptNumber}
                  onChange={(e) => setReceiptNumber(e.target.value)}
                  placeholder="Enter Receipt Number"
                  required
                />
                <button type="submit" disabled={loading}>
                  {loading ? 'Processing...' : 'Deduct'}
                </button>
              </form>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
